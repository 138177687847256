import React, { useState, useEffect } from "react";
import { ModalHeader } from "../../common/ModalHeader";
import { toast } from "react-hot-toast";
import { reviewListing } from "../../../api/admin";
import Loader from "../../common/Loader";

const ListingModal = ({ selectedData, handleClose, handleInitial }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        service_code: selectedData?.code,
      };
      const response = await reviewListing(_requestData);
      if (response.error === 0) {
        setData(response.data);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  const closeModal = () => {
    setData({});
    setIsLoading(false);
    handleClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-3xl">
        <ModalHeader title={"Listing Details"} onClick={closeModal} type={0} />
        {/* Design layout with details */}
        <div>
          <div className="overflow-y-scroll" style={{ maxHeight: "590px" }}>
            <div className="flex flex-col items-center text-center w-full border-b pb-4">
              <p className="text-lg font-semibold">
                {data?.service_listing?.title}
              </p>
              <p className="text-gray-400">{data?.service_listing?.subtitle}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mt-4 gap-x-8">
              <div className="flex flex-col w-full">
                <div className="flex flex-wrap justify-between">
                  <div>
                    <p className="text-xs text-gray-400">Merchant</p>
                    <p>{data?.merchant?.merchant_name}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-400">Service Type</p>
                    <p>{data?.service_listing?.service_name}</p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-400">Location</p>
                  <p>{data?.service_listing?.location}</p>
                </div>

                {/* About */}
                <div className="mt-4">
                  <p className="text-xs text-gray-400 ">About</p>
                  <p>{data?.service_listing?.about}</p>
                </div>

                {/* Special Inclusions */}
                <div className="mt-4">
                  <p className="text-xs text-gray-400 ">Special Inclusions</p>
                  <p>{data?.service_listing?.special_inclusions}</p>
                </div>

                {/* Additional Details */}
                <div className="mt-4">
                  <p className="text-xs text-gray-400">Additional Details</p>
                  <div className="grid grid-cols-2 gap-y-4">
                    <div>
                      <p className="text-xs text-gray-400 ">Service Price</p>
                      <p>PHP {data?.service_listing?.service_price}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">Pax</p>
                      <p>{data?.service_listing?.pax}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">Reservation Fee</p>
                      <p>PHP {data?.service_listing?.reservation_fee}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">Themes</p>
                      <p>{data?.service_listing?.theme}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">Time Limit</p>
                      <p>{data?.service_listing?.time_limit} hour(s)</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">Date Created</p>
                      <p>{data?.service_listing?.date_created}</p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-400 ">
                        Last Date Modified
                      </p>
                      <p>{data?.service_listing?.date_modified}</p>
                    </div>

                    <div>
                      {/* <p>
                  Status: {data?.service_listing?.status}
                </p> */}
                      {/* <p>Address: {data?.service_listing?.address}</p>
                    <p>Region: {data?.service_listing?.region}</p>
                    <p>Province: {data?.service_listing?.province}</p>
                    <p>Municipality: {data?.service_listing?.municipality}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <p className="text-xs text-gray-400 ">Cover Image</p>
                  <div className="flex flex-wrap gap-4">
                    <div className="w-full  mt-2">
                      <a
                        href={data?.service_listing?.cover_img}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={data?.service_listing?.cover_img}
                          alt="Cover"
                          className="w-full rounded-lg border"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-400 ">Service Images</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
                    {data?.service_img?.map((imageUrl, index) => (
                      <div key={index} className="w-full">
                        <a
                          href={imageUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={imageUrl}
                            alt={`Image ${index + 1}`}
                            className="w-full rounded-lg border h-28"
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <button
              onClick={() => closeModal()}
              className="w-full border p-3 rounded-lg hover:opacity-90"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingModal;
