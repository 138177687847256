import * as React from "react";
export const Wallet = (props) => {
  const { fill } = props;
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M17.25 5.9375H16.3125C16.4063 5.6875 16.4688 5.40625 16.4688 5.09375V4.03125C16.4688 2.78125 15.4688 1.78125 14.2188 1.78125H2.8125C1.5625 1.78125 0.5625 2.78125 0.5625 4.03125V5.09375V16C0.5625 17.2188 1.53125 18.1875 2.75 18.1875H17.2812C18.5 18.1875 19.4688 17.2188 19.4688 16V8.125C19.4375 6.9375 18.4688 5.9375 17.25 5.9375ZM18.0312 13.7812H15.1875C15.0625 13.7812 14.9375 13.6562 14.9375 13.5312V10.625C14.9375 10.5 15.0625 10.375 15.1875 10.375H18.0312V13.7812ZM1.96875 4.03125C1.96875 3.5625 2.34375 3.1875 2.8125 3.1875H14.25C14.7188 3.1875 15.0938 3.5625 15.0938 4.03125V5.09375C15.0938 5.5625 14.7188 5.9375 14.25 5.9375H2.8125C2.34375 5.9375 1.96875 5.5625 1.96875 5.09375V4.03125ZM17.25 16.8125H2.75C2.3125 16.8125 1.96875 16.4687 1.96875 16.0312V7.1875C2.21875 7.28125 2.5 7.34375 2.8125 7.34375H17.2812C17.7188 7.34375 18.0625 7.6875 18.0625 8.125V8.96875H15.2188C14.3125 8.96875 13.5625 9.71875 13.5625 10.625V13.5C13.5625 14.4062 14.3125 15.1562 15.2188 15.1562H18.0625V16C18.0312 16.4375 17.6875 16.8125 17.25 16.8125Z"
        fill={fill}
      />
      <path
        d="M16.5625 12.6562C16.8904 12.6562 17.1563 12.3904 17.1563 12.0625C17.1563 11.7346 16.8904 11.4688 16.5625 11.4688C16.2346 11.4688 15.9688 11.7346 15.9688 12.0625C15.9688 12.3904 16.2346 12.6562 16.5625 12.6562Z"
        fill={fill}
      />
    </svg>
  );
};
