import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { reviewMerchant } from "../../../../api/admin";
import Loader from "../../../common/Loader";
import { ModalHeader } from "../../../common/ModalHeader";

const ReviewMerchantModal = ({ selectedData, handleClose, handleInitial }) => {
  const [merchant, setMerchant] = useState({});
  const [merchantApplication, setMerchantApplication] = useState([]);
  const [accountApplication, setAccountApplication] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isShowDocument, setShowDocs] = useState(true);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        merchant_code: selectedData?.code,
      };
      const response = await reviewMerchant(_requestData);
      if (response.error === 0) {
        setMerchant(response.data.merchant);
        setMerchantApplication(response.data.merchant_application);
        setAccountApplication(response.data.account_application);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  const closeModal = () => {
    setMerchant({});
    setMerchantApplication([]);
    setIsLoading(false);
    handleClose();
    setShowDocs(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: selectedData.flag,
        remarks: remarks,
        merchant_code: selectedData.code,
      };

      const response = await reviewMerchant(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        setIsLoading(false);
        handleInitial();
        closeModal();
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast("Failed. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full w-600">
        <ModalHeader title={"Review Merchant"} onClick={closeModal} type={0} />

        <div className="overflow-y-scroll" style={{ maxHeight: "590px" }}>
          <div>
            <div className="mb-2">
              <p className="text-gray-400 text-xs">Name</p>
              <p>{merchant.merchant_name}</p>
            </div>
            <div>
              <p className="text-gray-400 text-xs">Address</p>
              <p>{merchant.address}</p>
            </div>
          </div>

          <div className="border-b mt-5 gap-x-10 flex flex-row">
            <div
              onClick={() => setShowDocs(true)}
              className={`${
                isShowDocument && "border-b border-primary-500"
              } cursor-pointer hover:text-primary-500 hover:border-primary-500`}
            >
              Documents
            </div>
            <div
              onClick={() => setShowDocs(false)}
              className={`${
                !isShowDocument && "border-b border-primary-500"
              } cursor-pointer hover:text-primary-500 hover:border-primary-500`}
            >
              Others
            </div>
          </div>
          {isShowDocument ? (
            <div className="flex gap-4 overflow-x-auto py-2">

{merchantApplication?.map((doc, index) => {
                      return (
                        <div key={index} className="w-40 flex-shrink-0">
                          {doc.includes("pdf") ? (
                            <div>
                              <iframe
                                title="PDF Preview"
                                src={doc}
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              >
                                This browser does not support PDFs. Please
                                download the PDF to view it.
                              </iframe>
                              <span
                                className="mt-1 w-full text-primary-500 cursor-pointer text-center text-xs"
                                onClick={() => window.open(doc, "_blank")}
                              >
                                Download
                              </span>
                            </div>
                          ) : (
                            <a
                              href={doc}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={doc}
                                alt={`Document ${index}`}
                                className="w-full rounded-lg border"
                              />
                            </a>
                          )}
                        </div>
                      );
                    })}

              
            </div>
          ) : (
            <div className="flex gap-4 overflow-x-auto py-2">
              {accountApplication?.map((doc, index) => {
                      return (
                        <div key={index} className="w-40 flex-shrink-0">
                          {doc.includes("pdf") ? (
                            <div>
                              <iframe
                                title="PDF Preview"
                                src={doc}
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              >
                                This browser does not support PDFs. Please
                                download the PDF to view it.
                              </iframe>
                              <span
                                className="mt-1 w-full text-primary-500 cursor-pointer text-center text-xs"
                                onClick={() => window.open(doc, "_blank")}
                              >
                                Download
                              </span>
                            </div>
                          ) : (
                            <a
                              href={doc}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={doc}
                                alt={`Document ${index}`}
                                className="w-full rounded-lg border"
                              />
                            </a>
                          )}
                        </div>
                      );
                    })}


            </div>
          )}
        </div>

        <div className="py-4">
          <label className="text-gray-400 text-sm">Remarks</label>
          <input
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primary-500"
          />
        </div>

        <div className="text-center flex gap-x-4">
          <button
            onClick={() => closeModal()}
            className="w-full border p-3 rounded-lg hover:opacity-90"
          >
            Close
          </button>

          <button
            onClick={handleSave}
            className="w-full bg-primary-500 hover:bg-primary-600 capitalize text-white py-2 px-4 rounded-lg focus:outline-none focus:bg-primary-600"
          >
            {isLoading ? <Loader /> : selectedData.flag}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewMerchantModal;
