import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Close from "../../../assets/svg/Close";
import { PrimaryButton } from "../PrimaryButton";
import { logout, updateLogoutModal } from "../../../store/slicers/authSlice";
import { logoutAdmin } from "../../../api/admin";

export const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(updateLogoutModal(false));
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const response = await logoutAdmin();
      if (response.error === 0) {
        setIsLoading(false);
        dispatch(logout());
        // reset details!!
        handleClose();
        navigate("/login");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
        <div id="logout" className="px-6 mb-2">
          <div className="text-lg font-semibold mb-5">Logout your account</div>
          <div className="">Are you sure to logout?</div>
        </div>
        <div className="px-6 flex gap-x-4 mt-6">
          <button
            onClick={() => handleClose()}
            className="w-full border py-3 rounded-lg hover:opacity-90"
          >
            <p className="text-sm">Cancel</p>
          </button>
          <PrimaryButton
            title={"Yes"}
            isLoading={isLoading}
            onClick={() => {
              handleLogout();
            }}
          />
        </div>
      </div>
    </div>
  );
};
