import * as React from "react";

export const Grid = (props) => {
  const { fill } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M6.78125 1.0625H2.8125C1.75 1.0625 0.875 1.9375 0.875 3V6.96875C0.875 8.03125 1.75 8.90625 2.8125 8.90625H6.78125C7.84375 8.90625 8.71875 8.03125 8.71875 6.96875V3.03125C8.75 1.9375 7.875 1.0625 6.78125 1.0625ZM7.34375 7C7.34375 7.3125 7.09375 7.5625 6.78125 7.5625H2.8125C2.5 7.5625 2.25 7.3125 2.25 7V3.03125C2.25 2.71875 2.5 2.46875 2.8125 2.46875H6.78125C7.09375 2.46875 7.34375 2.71875 7.34375 3.03125V7Z"
        fill={fill}
      />
      <path
        d="M17.1875 1.0625H13.2188C12.1563 1.0625 11.2812 1.9375 11.2812 3V6.96875C11.2812 8.03125 12.1563 8.90625 13.2188 8.90625H17.1875C18.25 8.90625 19.125 8.03125 19.125 6.96875V3.03125C19.125 1.9375 18.25 1.0625 17.1875 1.0625ZM17.75 7C17.75 7.3125 17.5 7.5625 17.1875 7.5625H13.2188C12.9063 7.5625 12.6563 7.3125 12.6563 7V3.03125C12.6563 2.71875 12.9063 2.46875 13.2188 2.46875H17.1875C17.5 2.46875 17.75 2.71875 17.75 3.03125V7Z"
        fill={fill}
      />
      <path
        d="M6.78125 11.0312H2.8125C1.75 11.0312 0.875 11.9063 0.875 12.9688V16.9375C0.875 18 1.75 18.875 2.8125 18.875H6.78125C7.84375 18.875 8.71875 18 8.71875 16.9375V13C8.75 11.9062 7.875 11.0312 6.78125 11.0312ZM7.34375 16.9687C7.34375 17.2812 7.09375 17.5312 6.78125 17.5312H2.8125C2.5 17.5312 2.25 17.2812 2.25 16.9687V13C2.25 12.6875 2.5 12.4375 2.8125 12.4375H6.78125C7.09375 12.4375 7.34375 12.6875 7.34375 13V16.9687Z"
        fill={fill}
      />
      <path
        d="M17.1875 11.0312H13.2188C12.1563 11.0312 11.2812 11.9063 11.2812 12.9688V16.9375C11.2812 18 12.1563 18.875 13.2188 18.875H17.1875C18.25 18.875 19.125 18 19.125 16.9375V13C19.125 11.9062 18.25 11.0312 17.1875 11.0312ZM17.75 16.9687C17.75 17.2812 17.5 17.5312 17.1875 17.5312H13.2188C12.9063 17.5312 12.6563 17.2812 12.6563 16.9687V13C12.6563 12.6875 12.9063 12.4375 13.2188 12.4375H17.1875C17.5 12.4375 17.75 12.6875 17.75 13V16.9687Z"
        fill={fill}
      />
    </svg>
  );
};
