import * as React from "react";
export const Hat = (props) => {
  const { fill } = props;
  return (
    <svg width={18} height={20} viewBox="0 0 18 20" fill="none">
      <path
        d="M17.6875 17.1875L10.625 4.46875C11.0312 4.0625 11.2812 3.46875 11.2812 2.84375C11.2812 1.59375 10.25 0.5625 8.99998 0.5625C7.74998 0.5625 6.71873 1.5625 6.71873 2.84375C6.71873 3.46875 6.96873 4.03125 7.37498 4.46875L0.312478 17.1875C0.0312283 17.6562 0.0624784 18.25 0.343728 18.7188C0.624978 19.1875 1.12498 19.4688 1.65623 19.4688H16.3437C16.9062 19.4688 17.375 19.1875 17.6562 18.7188C17.9375 18.25 17.9375 17.6562 17.6875 17.1875ZM8.99998 5.125C9.12498 5.125 9.24998 5.125 9.37498 5.09375L10.4687 7.09375L6.68748 8.53125L8.59373 5.09375C8.74998 5.125 8.87498 5.125 8.99998 5.125ZM5.65623 10.4688L11.1875 8.34375L12.3125 10.375L3.87498 13.6562L5.65623 10.4688ZM8.99998 1.96875C9.49998 1.96875 9.87498 2.375 9.87498 2.84375C9.87498 3.1875 9.65623 3.5 9.37498 3.65625C9.24998 3.71875 9.12498 3.75 8.99998 3.75C8.87498 3.75 8.74998 3.71875 8.62498 3.65625C8.31248 3.5 8.12498 3.21875 8.12498 2.84375C8.12498 2.34375 8.49998 1.96875 8.99998 1.96875ZM1.56248 17.9688C1.53123 17.9375 1.53123 17.875 1.56248 17.8438L2.84373 15.5625L13 11.5938L14.0625 13.5L2.96873 18.0312H1.65623C1.59373 18.0312 1.56248 18.0312 1.56248 17.9688ZM16.4375 17.9688C16.4062 18 16.375 18.0312 16.3437 18.0312H6.65623L14.7187 14.7188L16.4687 17.8438C16.4687 17.9062 16.4687 17.9375 16.4375 17.9688Z"
        fill={fill}
      />
    </svg>
  );
};
