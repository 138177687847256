import React, { useEffect, useState } from "react";
import { adminDashboard, settings } from "../../api/admin";
import Loader from "../common/Loader";
import { toast } from "react-hot-toast";

const Dashboard = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [fees, setFees] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const keys = {
    client_service_fee: "Service Fee",
    client_min_s_fee: "Minimum Service Fee",
    client_max_s_fee: "Max Service Fee",
    merchant_service_fee: "Service Fee",
    merchant_min_s_fee: "Minimum Service Fee",
    merchant_max_s_fee: "Max Service Fee",
  };
  const colors = ["bg-green-50", "bg-blue-50", "bg-yellow-50", "bg-orange-50"];

  const [data, setData] = useState({});

  const [editedKey, setEditedKey] = useState(null); // Track the edited key

  useEffect(() => {
    handleInitial();
    handleInitialDashboard();
  }, []);

  const handleInitialDashboard = async () => {
    try {
      const response = await adminDashboard();
      if (response.error === 0) {
        setIsLoading(false);
        setData(response.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInitial = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await settings(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setFees(response.data.settings);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEdit = (key) => {
    setEditedKey(key); // Set the edited key for the specific input
  };

  const handleUpdateSetting = async (key, value) => {
    try {
      const _requestData = {
        flag: "update",
        setting_key: key,
        setting_value: value,
      };
      const response = await settings(_requestData);
      if (response.error === 0) {
        toast.success(response.msg);
      } else {
        toast.error(response.error_msg);
      }
    } catch (error) {
      toast.error("Failed. Please try again.");
    }
  };

  const handleUpdate = async (key) => {
    if (key && editedKey === key) {
      await handleUpdateSetting(key, fees[key]);
      setEditedKey(null); // Reset editedKey state after updating
    }
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    setFees({
      ...fees,
      [key]: value,
    });
    setEditedKey(key); // Track the key that has been edited
  };

  return (
    <div className="px-4 pb-4">
      {isLoading ? (
        <div className="flex items-center justify-center h-20">
          <Loader borderColor="border-primary-500" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-4">
            {Object.entries(data).map(([key, value], index) => (
              <div
                key={key}
                className={`p-4 gap-x-4 rounded-xl flex bg-white border`}
              >
                <div className="w-12 h-12 hidden md:block">
                  <div
                    className={`${key} w-full h-full bg-no-repeat bg-cover`}
                  ></div>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm text-gray-400 font-semibold capitalize">
                    {key.replace(/_/g, " ")}
                  </p>
                  <p className="text-xl">{value}</p>
                </div>
              </div>
            ))}
          </div>
          <h2 className="text-xl font-semibold py-4 border-b">Update Rates</h2>

          <div className="flex gap-4 mt-4 flex-wrap justify-between">
            <div className="flex-1 flex-col bg-white border rounded-xl">
              <p className="py-4 px-2 border-b bg-gray-100 font-semibold rounded-t-xl">
                Client Fee
              </p>
              {Object.entries(fees)
                .filter(([key, value]) => key.includes("client"))
                .map(([key, value], index) => {
                  return (
                    <div
                      key={key}
                      className={`flex flex-wrap gap-x-4 ${
                        index !== 0 && "border-t"
                      } py-4 px-2 justify-between`}
                    >
                      <label className="text-sm text-gray-400 self-center">
                        {keys[key]}
                      </label>
                      <div className="flex items-center space-x-2">
                        {editedKey === key ? (
                          <div className="gap-x-10 flex flex-row items-center">
                            <input
                              type="text"
                              value={value}
                              onChange={(e) => handleChange(e, key)}
                              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500 flex-grow"
                            />
                            <div
                              className="text-primary-500 text-sm border border-primary-500 rounded-full px-4 py-1"
                              onClick={() => handleUpdate(key)}
                            >
                              Save
                            </div>
                          </div>
                        ) : (
                          <div className="gap-x-10 flex flex-row items-center">
                            <div className="w-full py-2">{value}</div>
                            <div
                              className="text-primary-500 text-sm border border-primary-500 rounded-full px-4 py-1"
                              onClick={() => handleEdit(key)}
                            >
                              Edit
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex-1 flex-col bg-white border rounded-xl">
              <p className="py-4 px-2 border-b bg-gray-100 font-semibold rounded-t-xl">
                Merchant Fee
              </p>
              {Object.entries(fees)
                .filter(([key, value]) => key.includes("merchant"))
                .map(([key, value], index) => {
                  return (
                    <div
                      key={key}
                      className={`flex flex-wrap gap-x-4 ${
                        index !== 0 && "border-t"
                      } py-4 px-2 justify-between`}
                    >
                      <label className="text-sm text-gray-400 self-center">
                        {keys[key]}
                      </label>
                      <div className="flex items-center space-x-2">
                        {editedKey === key ? (
                         <div className="gap-x-10 flex flex-row items-center">
                            <input
                              type="text"
                              value={value}
                              onChange={(e) => handleChange(e, key)}
                              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500 flex-grow"
                            />
                            <div
                              className="text-primary-500 text-sm border border-primary-500 rounded-full px-4 py-1"
                              onClick={() => handleUpdate(key)}
                            >
                              Save
                            </div>
                          </div>
                        ) : (
                          <div className="gap-x-10 flex flex-row items-center">
                            <div className="w-full py-2">{value}</div>
                            <div
                              className="text-primary-500 text-sm border border-primary-500 rounded-full px-4 py-1"
                              onClick={() => handleEdit(key)}
                            >
                              Edit
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
