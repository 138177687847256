import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBankModal } from "../../../store/slicers/authSlice";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";
import { ModalHeader } from "../../common/ModalHeader";
import { bankInfo } from "../../../api/admin";

export const BankInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    handleInit();
  }, []);

  const handleClose = () => {
    dispatch(updateBankModal(false));
  };

  const handleInit = async () => {
    setIsLoading(true);
    try {
      const response = await bankInfo({ flag: "init" });
      if (response.error === 0) {
        setBankName(response?.data?.bank_info?.bank_name || "");
        setAccountName(response?.data?.bank_info?.account_name || "");
        setAccountNumber(response?.data?.bank_info?.account_no || "");
        setIsLoading(false);
      } else {
        handleClose();
        setIsLoading(false);
      }
    } catch (error) {
      handleClose();
      setIsLoading(false);
    }
  };
  const handleSave = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "save",
        bank_name: bankName,
        account_name: accountName,
        account_no: accountNumber,
      };
      const response = await bankInfo(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        handleClose();
        toast.success(response.msg);
      } else {
        setError(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-500">
        <ModalHeader
          title={"Bank Information"}
          onClick={() => {
            handleClose();
          }}
          type={0}
        />

        <div id="bank" className=" flex flex-col gap-y-2">
          <div id="bankName">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label htmlFor="bankName" className="px-2 text-xs text-gray-400">
                Bank Name
              </label>
              <div className="flex">
                <input
                  autoFocus={true}
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="bankName"
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div id="accountName">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label
                htmlFor="accountName"
                className="px-2 text-xs text-gray-400"
              >
                Account Name
              </label>
              <div className="flex">
                <input
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="accountName"
                  type="text"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div id="accountNumber">
            <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
              <label
                htmlFor="accountNumber"
                className="px-2 text-xs text-gray-400"
              >
                Account Number
              </label>
              <div className="flex">
                <input
                  className="resize-none border-none px-2 outline-none w-full bg-transparent"
                  id="accountNumber"
                  type="text"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="h-3 mt-2">
            {error && (
              <span className="text-xs text-red-500 flex flex-row item-center">
                {error}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-x-4 mb-4">
          <button
            className="border-gray-300 border px-10 w-full font-semibold py-4 rounded-xl hover:opacity-90"
            onClick={() => handleClose()}
          >
            <p className="text-sm">Cancel</p>
          </button>
          <button
            className="bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
            onClick={
              isLoading
                ? () => {}
                : () => {
                    handleSave();
                  }
            }
          >
            {isLoading ? <Loader /> : <p className="text-sm">Proceed</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
