import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Hat } from "../../assets/svg/Hat";
import { Calendar } from "../../assets/svg/Calendar";
import { Cottage } from "../../assets/svg/Cottage";
import { Handshake } from "../../assets/svg/Handshake";
import { List } from "../../assets/svg/List";
import { Square } from "../../assets/svg/Square";
import { UserCheck } from "../../assets/svg/UserCheck";
import { Wallet } from "../../assets/svg/Wallet";
import { Grid } from "../../assets/svg/Grid";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("/");

  const sidebar = [
    {
      text: "Dashboard",
      url: "/",
    },
    {
      text: "Service Types",
      url: "/servicetypes",
    },
    {
      text: "Events",
      url: "/events",
    },

    {
      text: "Listings",
      url: "/listings",
    },
    {
      text: "Payments",
      url: "/payments",
    },

    {
      text: "Clients",
      url: "/clients",
    },

    {
      text: "Merchants",
      url: "/merchants",
    },

    {
      text: "For Review",
      url: "/for-review",
    },
    {
      text: "Reports",
      url: "/reports",
    },
  ];

  const location = useLocation();
  const logoutModal = useSelector((state) => state.auth.logoutModal);
  const bankModal = useSelector((state) => state.auth.bankModal);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const isActive = (path) => {
    return path === activeLink
      ? "text-primary-500 bg-primary-500 bg-opacity-5 border-r-2 border-primary-500"
      : "";
  };

  return (
    <div
      className="text-sm text-gray-500 h-screen bg-white w-80 overflow-y-auto"
      style={{ boxShadow: "7px 10px 20px rgba(92, 115, 160, 0.07)", minHeight: "100vh"  }}
    >
      <div className="h-full flex flex-col">
        <div className="mb-5">
          <div className="w-full h-16">
            <div className="logo h-full w-full bg-center" />
          </div>
        </div>
        <ul>
          {sidebar.map((data, key) => {
            const IconComponent = getIconComponent(data.url);
            return (
              <li className="mb-2" key={key}>
                <Link
                  to={data.url}
                  className={`block p-4 flex transition duration-100  ${isActive(
                    data.url
                  )}`}
                >
                  <div className="w-5 h-5 mr-2 hover:text-primary-500">
                    <IconComponent
                      fill={data.url === activeLink ? "#604EA0" : "#1F2A37"}
                    />
                  </div>
                  {data.text}
                </Link>
              </li>
            );
          })}
        </ul>
       </div>
    </div>
  );
};

function getIconComponent(iconName) {
  switch (iconName) {
    case "/":
      return Grid;
    case "/servicetypes":
      return Hat;
    case "/events":
      return Calendar;
    case "/merchants":
      return Cottage;
    case "/clients":
      return Handshake;
    case "/listings":
      return List;
    case "/reports":
      return Square;
    case "/for-review":
      return UserCheck;
    case "/payments":
      return Wallet;
    default:
      return Grid;
  }
}


export default Sidebar;
