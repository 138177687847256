import * as React from "react";
export const List = (props) => {
  const { fill } = props;
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M3.84375 1.09375H1.875C1.15625 1.09375 0.53125 1.6875 0.53125 2.4375V4.40625C0.53125 5.125 1.125 5.75 1.875 5.75H3.84375C4.5625 5.75 5.1875 5.15625 5.1875 4.40625V2.40625C5.15625 1.6875 4.5625 1.09375 3.84375 1.09375ZM3.75 4.3125H1.96875V2.5H3.75V4.3125Z"
        fill={fill}
      />
      <path
        d="M8.03125 4.125H18.75C19.125 4.125 19.4688 3.8125 19.4688 3.40625C19.4688 3 19.1562 2.6875 18.75 2.6875H8.03125C7.65625 2.6875 7.3125 3 7.3125 3.40625C7.3125 3.8125 7.65625 4.125 8.03125 4.125Z"
        fill={fill}
      />
      <path
        d="M3.84375 7.5H1.875C1.15625 7.5 0.53125 8.09375 0.53125 8.84375V10.8125C0.53125 11.5312 1.125 12.1562 1.875 12.1562H3.84375C4.5625 12.1562 5.1875 11.5625 5.1875 10.8125V8.84375C5.15625 8.09375 4.5625 7.5 3.84375 7.5ZM3.75 10.7187H1.96875V8.90625H3.75V10.7187Z"
        fill={fill}
      />
      <path
        d="M18.75 9.125H8.03125C7.65625 9.125 7.3125 9.4375 7.3125 9.84375C7.3125 10.25 7.625 10.5312 8.03125 10.5312H18.75C19.125 10.5312 19.4688 10.2187 19.4688 9.84375C19.4688 9.46875 19.125 9.125 18.75 9.125Z"
        fill={fill}
      />
      <path
        d="M3.84375 14.2812H1.875C1.15625 14.2812 0.53125 14.875 0.53125 15.625V17.5937C0.53125 18.3125 1.125 18.9375 1.875 18.9375H3.84375C4.5625 18.9375 5.1875 18.3437 5.1875 17.5937V15.625C5.15625 14.875 4.5625 14.2812 3.84375 14.2812ZM3.75 17.5H1.96875V15.6875H3.75V17.5Z"
        fill={fill}
      />
      <path
        d="M18.75 15.875H8.03125C7.65625 15.875 7.3125 16.1875 7.3125 16.5937C7.3125 17 7.625 17.3125 8.03125 17.3125H18.75C19.125 17.3125 19.4688 17 19.4688 16.5937C19.4688 16.1875 19.125 15.875 18.75 15.875Z"
        fill={fill}
      />
    </svg>
  );
};
