import React, { useEffect, useState } from "react";
import { ModalHeader } from "../../common/ModalHeader";
import { eventList } from "../../../api/admin";
import dayjs from "dayjs";

const EventsModal = ({ selectedData, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    handleView();
  }, []);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        booking_code: selectedData?.code,
      };
      const response = await eventList(_requestData);
      console.log(response, "response");
      if (response.error === 0) {
        setData(response.data?.booking_info);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  const closeModal = () => {
    handleClose();
  };

  const statusChecker = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "For Checking";
      case "2":
        return "Posted";
      case "3":
        return "Declined";
      default:
        break;
    }
  };

  const statusCheckerColor = (status) => {
    switch (status) {
      case "0":
        return "text-yellow-500";
      case "1":
        return "text-orange-500";
      case "2":
        return "text-green-500";
      case "3":
        return "text-red-500";
      default:
        break;
    }
  };
  //   {
  //     "location": "Quezon City",
  //     "pax": "22",
  //     "service_fee": "2,000.00",
  //     "reservation_fee": "35,000.00",
  //     "surcharge_fee": "0.00",
  //     "total_amount": "702,000.00",
  //     "remarks": "Aaaaaa",
  //     "booking_status": "4",
  //     "payment_status": "0",
  //     "date_created": "2024-02-04 04:52:25",
  //     "date_confirmation": null,
  //     "full_name": "qweqwe qweqwe",
  //     "account_code": "a47d344d78",
  //     "merchant_code": "a5595e6147",
  //     "merchant_name": "World of Likha V2",
  //     "service_code": "9e617cdff6",
  //     "service_name": "Catering"
  // }
  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-3xl">
        <ModalHeader title={"Event Details"} onClick={closeModal} type={0} />

        <div className="p-2">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
            <div className="mb-4">
              <p className="text-gray-400 text-xs">Code</p>
              <p className="select-all">{data?.booking_code}</p>
            </div>
            <div className="mb-4">
              <p className="text-gray-400 text-xs">Date</p>
              <p className="select-all">
                {dayjs(data?.start_date).format("MMM DD, YY h:MM A")}{" "}
                {data?.start_date !== data?.end_date &&
                  `- ${dayjs(data?.end_date).format("MMM DD, YY h:MM A")}`}
              </p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Client Name</p>
              <p className="select-all">{data?.full_name}</p>
            </div>


            <div className="mb-4">
              <p className="text-gray-400 text-xs">Location</p>
              <p className="select-all">{data?.location}</p>
            </div>


            <div className="mb-4">
              <p className="text-gray-400 text-xs">Service Fee</p>
              <p className="select-all">{data?.service_fee}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Surcharge Fee</p>
              <p className="select-all">{data?.surcharge_fee}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Remarks</p>
              <p className="select-all">{data?.remarks}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Payment Status</p>
              <p className="select-all">{data?.payment_status}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Date Confirmation</p>
              <p className="select-all">{data?.date_confirmation}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Merchant Code</p>
              <p className="select-all">{data?.merchant_code}</p>
            </div>

            {["3", "4", "5", "33", "44"].includes(data?.booking_status) && (
              <>
                <div className="mb-4">
                  <p className="text-gray-400 text-xs">Cancel Remarks</p>
                  <p className="select-all">{data?.cancel_remarks}</p>
                </div>

                <div className="mb-4">
                  <p className="text-gray-400 text-xs">Cancellation Date</p>
                  <p className="select-all">{data?.cancellation_date}</p>
                </div>
              </>
            )}

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Pax</p>
              <p className="select-all">{data?.pax}</p>
            </div>

            {data?.merchant_service_fee && (
              <div className="mb-4">
                <p className="text-gray-400 text-xs">Merchant Service Fee</p>
                <p className="select-all">{data?.merchant_service_fee}</p>
              </div>
            )}

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Reservation Fee</p>
              <p className="select-all">{data?.reservation_fee}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Total Amount</p>
              <p className="select-all">{data?.total_amount}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Booking Status</p>
              <p className="select-all">{data?.booking_status}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Date Created</p>
              <p className="select-all">{data?.date_created}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Account Code</p>
              <p className="select-all">{data?.account_code}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Merchant Name</p>
              <p className="select-all">{data?.merchant_name}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Service Code</p>
              <p className="select-all">{data?.service_code}</p>
            </div>

            <div className="mb-4">
              <p className="text-gray-400 text-xs">Service Name</p>
              <p className="select-all">{data?.service_name}</p>
            </div>
          </div>

          <div className="mt-2">
            <button
              onClick={() => closeModal()}
              className="w-full border p-3 rounded-lg hover:opacity-90"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsModal;
