import React from "react";
import { ModalHeader } from "../../common/ModalHeader";

const ManualPaymentModal = ({ manualPayment, handleClose }) => {
  const closeModal = () => {
    handleClose();
  };

  const statusChecker = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "For Checking";
      case "2":
        return "Posted";
      case "3":
        return "Declined";
      case "4":
        return "Deposited";
      default:
        break;
    }
  };

  const statusCheckerColor = (status) => {
    switch (status) {
      case "0":
        return "text-yellow-500";
      case "1":
        return "text-orange-500";
      case "2":
        return "text-green-500";
      case "3":
        return "text-red-500";
      default:
        break;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-3xl">
        <ModalHeader title={"Payments"} onClick={closeModal} type={0} />

        <div>
          <div className="flex flex-col">
            <div className="grid grid-cols-3 gap-3">
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Booking Code</p>
                <p className="select-all">{manualPayment?.booking_code}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Payment Code</p>
                <p className="select-all">{manualPayment?.payment_code}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Merchant Code</p>
                <p className="select-all">{manualPayment?.merchant_code}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Date Posted</p>
                <p className="select-all">{manualPayment?.date_posted}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Client Name</p>
                <p className="select-all">{manualPayment?.client_name}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Refence Number</p>
                <p className="select-all">{manualPayment?.reference_no}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Amount Paid</p>
                <p className="select-all">{manualPayment?.amount_paid}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-400 text-xs">Amount Paid</p>
                <p
                  className={`${statusCheckerColor(
                    manualPayment?.status
                  )} select-all`}
                >
                  {statusChecker(manualPayment?.status)}
                </p>
              </div>
            </div>
            <div className="border-t my-4"></div>
            <div className="flex flex-row">
              {manualPayment?.receipt && (
                <div className="flex flex-col mr-4">
                  <p className="text-gray-400 text-xs">Receipt or Invoice</p>
                  <div className="w-80 p-3 mt-2 h-full border-dashed bg-gray-50 border rounded-xl">
                    <a
                      href={manualPayment?.receipt}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={manualPayment?.receipt}
                        alt="Receipt"
                        className="w-full h-60 w-60 rounded-lg border"
                      />
                    </a>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-col">
                  <p className="text-gray-400 text-xs">Account Name</p>
                  <p className="select-all">
                    {manualPayment?.account_name ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-gray-400 text-xs">Bank Name</p>
                  <p className="select-all">
                    {manualPayment?.bank_name ?? "-"}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-gray-400 text-xs">Payment Method</p>
                  <p className="select-all">
                    {manualPayment?.payment_method ?? "-"}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <button
                onClick={() => closeModal()}
                className="w-full border p-3 rounded-lg hover:opacity-90"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualPaymentModal;
