import React from "react";
import { Logout } from "./Auth/Logout";
import { BankInfo } from "../admin/BankInfo";

import { useDispatch, useSelector } from "react-redux";
import {
  updateBankModal,
  updateLogoutModal,
} from "../../store/slicers/authSlice";
function Topbar() {
  const dispatch = useDispatch();
  const logoutModal = useSelector((state) => state.auth.logoutModal);
  const bankModal = useSelector((state) => state.auth.bankModal);
  const auth = useSelector((state) => state.auth);

  return (
    <div className="flex p-2 justify-between bg-white border-b border-gray-300 w-full">
      <div className="flex flex-col justify-center">
        <span className="text-2xl font-semibold">
          Hello, {auth?.account_info?.firstname ?? "Admin"}
        </span>
        <span className="text-gray-400 text-sm">Welcome to Anyaya!</span>
      </div>
      <div className="flex flex-wrap">
        <div className="">
          <button
            onClick={() => dispatch(updateBankModal(true))}
            className="flex gap-x-4 w-full rounded-xl px-5 py-3 my-2 bg-primary-500 text-white  hover:bg-primary-600 transition duration-100"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M17.875 15.25V7.375C18.125 7.25 18.3125 7 18.4062 6.71875C18.5312 6.25 18.375 5.78125 17.9688 5.5L10.5625 0.71875C10.2187 0.5 9.75 0.5 9.40625 0.71875L2 5.5C1.59375 5.78125 1.40625 6.25 1.5625 6.71875C1.65625 7.03125 1.84375 7.25 2.125 7.375V15.25C1.65625 15.5937 1.3125 16.1875 1.3125 16.8125V17.5C1.3125 18.5625 2.1875 19.4375 3.25 19.4375H16.7188C17.7812 19.4375 18.6562 18.5625 18.6562 17.5V16.8125C18.6875 16.1562 18.375 15.5937 17.875 15.25ZM3.53125 14.8438V7.46875H6.90625V14.8438H3.53125ZM8.3125 7.46875H11.6875V14.8438H8.3125V7.46875ZM13.0938 7.46875H16.4688V14.8438H13.0938V7.46875ZM10 2.03125L16.2812 6.09375H3.71875L10 2.03125ZM17.2812 17.5C17.2812 17.8125 17.0312 18.0625 16.7188 18.0625H3.28125C2.96875 18.0625 2.71875 17.8125 2.71875 17.5V16.8125C2.71875 16.5 2.96875 16.25 3.28125 16.25H16.75C17.0625 16.25 17.3125 16.5 17.3125 16.8125V17.5H17.2812Z"
                fill="white"
              />
            </svg>
            Bank Info
          </button>
        </div>
        <div className="mx-4">
          <button
            onClick={() => dispatch(updateLogoutModal(true))}
            className="flex gap-x-4  w-full px-5 rounded-xl py-3 my-2 border border-red-500 text-red-500  hover:border-red-600 transition duration-100"
          >
            <svg
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
            >
              <path
                d="M11.125 0.5625H7.59375C6.78125 0.5625 6.09375 1.25 6.09375 2.0625V4.21875C6.09375 4.59375 6.40625 4.90625 6.78125 4.90625C7.15625 4.90625 7.5 4.59375 7.5 4.21875V2.03125C7.5 1.96875 7.53125 1.9375 7.59375 1.9375H11.125C11.875 1.9375 12.4688 2.53125 12.4688 3.28125V16.6875C12.4688 17.4375 11.875 18.0313 11.125 18.0313H7.59375C7.53125 18.0313 7.5 18 7.5 17.9375V15.7813C7.5 15.4063 7.1875 15.0938 6.78125 15.0938C6.375 15.0938 6.09375 15.4063 6.09375 15.7813V17.9375C6.09375 18.75 6.78125 19.4375 7.59375 19.4375H11.125C12.6562 19.4375 13.875 18.1875 13.875 16.6875V3.3125C13.875 1.78125 12.625 0.5625 11.125 0.5625Z"
                fill="#F56060"
              />
              <path
                d="M2.5 10.6875H8.09375C8.46875 10.6875 8.78125 10.375 8.78125 10C8.78125 9.625 8.46875 9.3125 8.09375 9.3125H2.53125L4.46875 7.34375C4.75 7.0625 4.75 6.625 4.46875 6.34375C4.1875 6.0625 3.75 6.0625 3.46875 6.34375L0.34375 9.53125C0.0625 9.8125 0.0625 10.25 0.34375 10.5313L3.46875 13.7188C3.59375 13.8438 3.78125 13.9375 3.96875 13.9375C4.15625 13.9375 4.3125 13.875 4.46875 13.75C4.75 13.4688 4.75 13.0313 4.46875 12.75L2.5 10.6875Z"
                fill="#F56060"
              />
            </svg>
            Logout
          </button>
        </div>
      </div>
      {logoutModal && <Logout />}
      {bankModal && <BankInfo />}
    </div>
  );
}

export default Topbar;
