import axios from "axios";
import { config } from "./config";
import { store } from "../store";
import {
  updateAccountInfo,
  updateNewAccount,
  updateToken,
} from "../store/slicers/authSlice";

const axiosInstance = axios.create({
  baseURL: config.BASE_URL,
  timeout: 400000,
  headers: {
    "Content-Type": "multipart/form-data; charset=UTF-8",
  },
});

// Interceptor for handling requests before they are sent
axiosInstance.interceptors.request.use(
  function (config) {
    const token = store.getState().auth.token;
    if (token) {
      config.data.token = token;
    }
    return config;
  },
  function (error) {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Interceptor for handling responses
axiosInstance.interceptors.response.use(
  function (response) {
    // handles logout
    if (response.data.error === -1001) {
      store.dispatch(updateToken(""));
      store.dispatch(updateAccountInfo({}));
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
