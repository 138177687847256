import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";

export async function logged() {
  const requestData = {
    ctl: "admin",
    act: "logged",
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function login(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "login",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function logoutAdmin(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "logout",
    ..._requestData,
  };
  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function serviceType(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "services_type",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function reviewMerchant(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "review_merchant",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function reviewListing(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "review_listings",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function reviewAccount(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "review_account",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function adminDashboard(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "dashboard",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function settings(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "settings",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function servicesReport(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "services_report",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

// ctl: admin
// act: client_list
// flag: init/view
// account_code: ?
export async function clientList(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "client_list",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

// ctl: admin
// act: merchant_list
// flag: init/view
// merchant_code: ?

export async function merchantList(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "merchant_list",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function serviceList(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "service_listing",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function bankInfo(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "bank_info",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function manualPayment(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "manual_payment",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function eventList(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "booking_list",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}


export async function cancelledEvents(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "cancelled_events",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function cancelledBookings(_requestData) {
  const requestData = {
    ctl: "admin",
    act: "cancelled_bookings",
    ..._requestData,
  };

  try {
    const response = await axiosInstance.post("/", requestData);
    return response.data;
  } catch (error) {
    return error;
  }
}