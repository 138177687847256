// ReviewListing.js
import React, { useEffect, useState } from "react";
import ReviewMerchantModal from "./Modal";
import { useNavigate } from "react-router-dom";
import { reviewListing } from "../../../../api/admin";
import Loader from "../../../common/Loader";

const ReviewListing = ({searchTerm}) => {
  const navigate = useNavigate();
  const [arrayData, setArray] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setData] = useState({ flag: "", code: "" });

  useEffect(() => {
    handleInitial();
  }, []);

  const handleInitial = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await reviewListing(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        console.log(response.data.services_list)
        setArray(response.data.services_list);
      } else {
        navigate(-1);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleReject = (service_code) => {
    setData({ flag: "reject", code: service_code });
    setShowModal(true);
  };

  const handleApprove = (service_code) => {
    setData({ flag: "approve", code: service_code });
    setShowModal(true);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 160);

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight - 160);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const filteredArray = arrayData?.filter(
    (listing) =>
      (listing?.merchant_name &&
        listing?.merchant_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (listing?.title &&
        listing?.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (listing?.service_name &&
        listing?.service_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  return (
    <>
      <div
        className="w-full overflow-y-auto"
        style={{ maxHeight: tableHeight }}
      >
        {isLoading && (
          <div className="flex items-center justify-center h-20">
            <Loader borderColor="border-primary-500" />
          </div>
        )}

        {!isLoading && (
          <div className="rounded-lg border overflow-hidden">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Service Type
                  </th>
                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Merchant
                  </th>
                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Price
                  </th>

                  <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                 {filteredArray.length === 0 && (
                  <td
                    colSpan={6}
                    className="p-3 text-center-webkit text-center select-all"
                  >
                    <svg
                      width="105"
                      height="127"
                      viewBox="0 0 105 127"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="37"
                        cy="117"
                        rx="37"
                        ry="10"
                        fill="#F0EEF7"
                      />
                      <path
                        d="M58.7978 5.39007C55.5062 5.7903 57.8587 14.162 64.0542 23.2606C64.6046 23.9102 66.0613 24.9391 67.4854 23.8584C69.2656 22.5075 66.1275 29.6361 70.2463 30.9265C73.5414 31.9589 71.2377 24.8749 69.674 21.2039C71.2342 23.4423 74.8476 27.501 76.819 25.8283C78.7905 24.1556 74.4292 20.9442 72.0022 19.5476C76.3467 19.6363 84.2658 19.0953 81.1858 16.2216C78.1058 13.348 72.4048 15.8563 69.9393 17.4697C73.3147 13.9146 79.2968 6.65911 76.2224 6.0776C73.148 5.49609 68.556 12.7418 66.6443 16.4374C67.2331 14.0769 68.738 8.5998 70.0477 5.57454C71.6848 1.79296 68.3828 -2.10456 65.7893 3.28603C63.788 7.44573 63.7508 13.0732 63.9648 15.6853C63.3082 12.4928 61.3272 5.08251 58.7978 5.39007Z"
                        fill="#FFDFAB"
                      />
                      <mask
                        id="mask0_4117_1783"
                        maskUnits="userSpaceOnUse"
                        x="3"
                        y="20"
                        width="71"
                        height="92"
                      >
                        <path
                          d="M66.4307 20.2387L73.2997 111.555L3.58344 89.7122L66.4307 20.2387Z"
                          fill="#E6DFFF"
                        />
                      </mask>
                      <g mask="url(#mask0_4117_1783)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M64.4365 57.8937C58.5671 56.0548 55.2998 49.8059 57.1387 43.9365C58.9776 38.0672 65.2264 34.7998 71.0958 36.6387C76.9652 38.4776 80.2326 44.7265 78.3936 50.5959C76.5547 56.4653 70.3059 59.7326 64.4365 57.8937ZM33.3367 67.7567C27.4674 65.9178 24.2 59.6689 26.0389 53.7995C27.8778 47.9301 34.1267 44.6628 39.9961 46.5017C45.8655 48.3406 49.1328 54.5895 47.2939 60.4588C45.455 66.3282 39.2061 69.5956 33.3367 67.7567ZM54.0845 77.5249C52.2456 83.3943 55.5129 89.6431 61.3823 91.482C67.2517 93.321 73.5005 90.0536 75.3395 84.1842C77.1784 78.3148 73.911 72.066 68.0416 70.2271C62.1722 68.3882 55.9234 71.6555 54.0845 77.5249ZM27.9983 99.6957C22.1289 97.8568 18.8616 91.6079 20.7005 85.7385C22.5394 79.8691 28.7882 76.6018 34.6576 78.4407C40.527 80.2796 43.7944 86.5284 41.9555 92.3978C40.1165 98.2672 33.8677 101.535 27.9983 99.6957Z"
                          fill="#F0EEF7"
                        />
                      </g>
                      <path
                        d="M6.31149 88.8659L71.6691 109.343L65.0496 24.3103L6.31149 88.8659Z"
                        stroke="#7F73A7"
                        strokeWidth="3"
                      />
                      <ellipse
                        cx="36.5289"
                        cy="9.96244"
                        rx="36.5289"
                        ry="9.96244"
                        transform="matrix(-0.954261 -0.298976 -0.298976 0.954261 76.1733 102.383)"
                        fill="#7F73A7"
                      />
                    </svg>
                    <p className="text-sm text-primary-500 mt-5">
                      No data found
                    </p>
                  </td>
                )}
                {filteredArray.map((type) => (
                  <tr
                    key={type.service_code}
                    className="hover:bg-gray-50 border-t"
                  >
                    <td className="p-3 text-left select-all">
                      {type.service_name}
                    </td>
                    <td className="p-3 text-left">{type.title}</td>
                    <td className="p-3 text-left select-all">
                      {type?.merchant_name}
                    </td>
                    <td className="p-3 text-left">{type.location}</td>
                    <td className="p-3 text-left">PHP {type.service_price}</td>
                    <td className="p-3 text-left">
                      <button
                        className="mr-4 text-green-500"
                        onClick={() => handleApprove(type.service_code)}
                      >
                        Approve
                      </button>

                      <button
                        className="text-red-500"
                        onClick={() => handleReject(type.service_code)}
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* Modal */}
      {showModal && (
        <ReviewMerchantModal
          selectedData={selectedData}
          handleClose={() => {
            setData({ flag: "", code: "" });
            setShowModal(false);
          }}
          handleInitial={() => handleInitial()}
        />
      )}
    </>
  );
};

export default ReviewListing;
