import { useEffect, useState } from "react";
import { logged } from "../../api/admin";
import { useDispatch } from "react-redux";
import { updateAccountInfo } from "../../store/slicers/authSlice";
const CLogged = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    try {
      const response = await logged();
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        dispatch(updateAccountInfo(accountInfo));
      } 
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // call logout
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? <div></div> : <>{children}</>;
};

export default CLogged;
