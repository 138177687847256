// Login.js
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { login } from "../../api/admin";
import Loader from "../common/Loader";
import md5 from "md5";
import { useDispatch } from "react-redux";
import { updateAccountInfo, updateToken } from "../../store/slicers/authSlice";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Add your login logic here
    setIsLoading(true);
    try {
      const _requestData = {
        username: username,
        password: md5(password),
      };
      const response = await login(_requestData);
      if (response.error === 0) {
        const accountInfo = response.data.account_info;
        setIsLoading(false);
        dispatch(updateToken(accountInfo.token));
        dispatch(updateAccountInfo(accountInfo.account_info));

        navigate("/")
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast("Failed. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl mb-4 text-center font-semibold">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-semibold mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-primary-500"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-primary-500"
              placeholder="Enter your password"
            />
          </div>
          <div className="text-center w-full">
            <button
              type="submit"
              className="w-full bg-primary-500 hover:bg-primary-600 text-white py-2 px-4 rounded focus:outline-none focus:bg-primary-600"
            >
              {isLoading ? <Loader /> : "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
