import React, { useState, useEffect } from "react";
import { ModalHeader } from "../../common/ModalHeader";
import { toast } from "react-hot-toast";
import { serviceType, servicesReport } from "../../../api/admin";
import Loader from "../../common/Loader";

const ReportsModal = ({ selectedData, handleClose, handleInitial }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        report_code: selectedData?.code,
      };
      const response = await servicesReport(_requestData);
      if (response.error === 0) {
        setData(response.data.report_service);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  const closeModal = () => {
    setData(selectedData?.name || "");
    setIsLoading(false);
    handleClose();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: selectedData.flag,
        report_code: selectedData?.code,
      };

      const response = await servicesReport(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        handleInitial();
        closeModal();
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast("Failed. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-md">
        <ModalHeader
          title={"Report"}
          onClick={() => {
            closeModal();
          }}
          type={0}
        />
  
        <div className="flex flex-col gap-4 mt-4">
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Title</p>
            <p>{data?.title}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Service Name</p>
            <p>{data?.service_name}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Guest Name</p>
            <p>{data?.guest_name}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Date Submitted</p>
            <p>{data?.date_submitted}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Remarks</p>
            <p>{data?.remarks}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-400 text-xs">Status</p>
            <p>{data?.status}</p>
          </div>
        </div>
  
        <div className="text-center w-full mt-4">
        <button
            onClick={() => closeModal()}
            className="w-full border p-3 rounded-lg hover:opacity-90"
          >
            Close
          </button>
          <button
            onClick={() => handleSave()}
            className="w-full bg-primary-500 hover:bg-primary-600 text-white py-2 px-4 rounded focus:outline-none focus:bg-primary-600"
          >
            {isLoading ? <Loader /> : "Resolve"}
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default ReportsModal;
