import * as React from "react";
export const Square = (props) => {
  const { fill } = props;
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_4110_418)">
        <path
          d="M17.5 0.5625H2.5C1.4375 0.5625 0.5625 1.4375 0.5625 2.5V17.5C0.5625 18.5625 1.4375 19.4688 2.53125 19.4688H17.5312C18.5938 19.4688 19.5 18.5938 19.5 17.5V2.5C19.4688 1.4375 18.5625 0.5625 17.5 0.5625ZM18.0625 17.5C18.0625 17.8125 17.8125 18.0625 17.5 18.0625H2.5C2.1875 18.0625 1.9375 17.8125 1.9375 17.5V2.5C1.96875 2.1875 2.1875 1.96875 2.5 1.96875H17.5C17.8125 1.96875 18.0625 2.21875 18.0625 2.53125V17.5Z"
          fill={fill}
        />
        <path
          d="M4.21875 5.8125H9C9.375 5.8125 9.71875 5.5 9.71875 5.09375C9.71875 4.6875 9.40625 4.375 9 4.375H4.21875C3.84375 4.375 3.5 4.6875 3.5 5.09375C3.5 5.5 3.84375 5.8125 4.21875 5.8125Z"
          fill={fill}
        />
        <path
          d="M13.9062 9.3125H4.21875C3.84375 9.3125 3.5 9.625 3.5 10.0312C3.5 10.4062 3.8125 10.75 4.21875 10.75H13.9062C14.2812 10.75 14.625 10.4375 14.625 10.0312C14.625 9.625 14.3125 9.3125 13.9062 9.3125Z"
          fill={fill}
        />
        <path
          d="M11.25 14.1875H4.21875C3.84375 14.1875 3.5 14.5 3.5 14.9062C3.5 15.3125 3.8125 15.625 4.21875 15.625H11.25C11.625 15.625 11.9687 15.3125 11.9687 14.9062C11.9687 14.5 11.6562 14.1875 11.25 14.1875Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4110_418">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
