import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Protected = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (isLoggedIn === "") {
      navigate("/login");
    } else {
      setLoading(false); // Set loading to false when authentication check is completed
    }
  }, [isLoggedIn, navigate]);

  // Render loading state until authentication check is completed
  if (loading) {
    return <div></div>;
  }

  // Render children if authenticated
  return <div className="mt-5 w-full">{children}</div>;
};

export default Protected;
