import * as React from "react";
export const UserCheck = (props) => {
  const { fill } = props;
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M14.125 10.1875C13.9687 10.1875 13.8125 10.1875 13.6875 10.2188C12.7812 9.1875 11.6875 8.4375 10.4687 8.0625C11.6875 7.375 12.5312 6.0625 12.5312 4.5625C12.5312 2.34375 10.7187 0.5625 8.53125 0.5625C6.3125 0.5625 4.53125 2.375 4.53125 4.5625C4.53125 6.0625 5.375 7.375 6.59375 8.0625C5.375 8.4375 4.25 9.1875 3.34375 10.25C2.03125 11.8125 1.28125 14 1.21875 16.375C1.21875 16.6562 1.375 16.9062 1.59375 17.0312C2.4375 17.4375 5.375 18.7812 8.53125 18.7812C9.4375 18.7812 10.375 18.6875 11.3125 18.5C12.0937 19.0938 13.0625 19.4688 14.125 19.4688C16.6875 19.4688 18.75 17.375 18.75 14.8438C18.75 12.3125 16.6875 10.1875 14.125 10.1875ZM8.53125 1.96875C9.96875 1.96875 11.125 3.125 11.125 4.5625C11.125 6 9.96875 7.15625 8.53125 7.15625C7.09375 7.15625 5.9375 6 5.9375 4.5625C5.9375 3.125 7.09375 1.96875 8.53125 1.96875ZM2.65625 15.9688C2.78125 14.0938 3.40625 12.4062 4.4375 11.1562C5.53125 9.875 7 9.15625 8.5625 9.15625C9.90625 9.15625 11.1562 9.6875 12.1875 10.625C10.625 11.375 9.53125 12.9688 9.53125 14.8125C9.53125 15.7188 9.78125 16.5313 10.25 17.25C9.6875 17.3125 9.125 17.375 8.59375 17.375C6.09375 17.375 3.71875 16.4688 2.65625 15.9688ZM14.125 18.0625C12.3437 18.0625 10.9062 16.625 10.9062 14.8438C10.9062 13.0625 12.3437 11.625 14.125 11.625C15.9062 11.625 17.3437 13.0625 17.3437 14.8438C17.3437 16.625 15.9062 18.0625 14.125 18.0625Z"
        fill={fill}
      />
      <path
        d="M15.1875 13.375L13.625 14.9063L13.375 14.6563C13.125 14.375 12.6562 14.3438 12.375 14.625C12.0937 14.875 12.0625 15.3438 12.3437 15.625L13.0625 16.4063C13.1875 16.5313 13.375 16.625 13.5625 16.625C13.5625 16.625 13.5625 16.625 13.5937 16.625C13.7812 16.625 13.9687 16.5625 14.0937 16.4375L16.1562 14.4063C16.4375 14.125 16.4375 13.6875 16.1562 13.4063C15.9062 13.0938 15.4687 13.0938 15.1875 13.375Z"
        fill={fill}
      />
    </svg>
  );
};
