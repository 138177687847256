// ReviewListing.js
import React, { useEffect, useRef, useState } from "react";
import ReviewListing from "./ReviewListing";
import ReviewMerchant from "./ReviewMerchant";
import ReviewAccount from "./ReviewAccount";

const ForReview = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [status, setStatus] = useState({ id: "listings", name: "Listings" });
  const statuses = [
    { id: "listings", name: "Listings" },
    { id: "merchants", name: "Merchants" },
    { id: "accounts", name: "Accounts" },
  ];
  const dropdownRef = useRef(null);

  const handleStatusSelect = (status) => {
    setStatus(status);
    setIsStatusOpen(false);
  };

  return (
    <div className="text-sm px-4 pb-4 w-full justify-center items-center">
      <div className="border bg-white p-4 rounded-xl mb-4 w-full flex flex-row justify-between">
        <h2 className="text-xl flex items-center text-center font-semibold">
          For Review
        </h2>

        <div className="flex flex-wrap gap-x-4" ref={dropdownRef}>
          <div
            onClick={() => setIsStatusOpen(!isStatusOpen)}
            className="cursor-pointer box-border flex flex-row items-center px-5 gap-2 w-72  bg-white border border-gray-300 rounded-md"
          >
            <p className={`w-full flex flex-1`}>{status?.name}</p>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.41429 5.03575L2.41428 5.03577L2.41709 5.03852L7.76709 10.2635L8.0011 10.4921L8.23403 10.2624L13.584 4.98741L13.584 4.98741L13.5857 4.98575C13.6805 4.89093 13.8195 4.89093 13.9143 4.98575C14.0088 5.0803 14.0091 5.2187 13.9151 5.31351C13.9149 5.31379 13.9146 5.31407 13.9143 5.31435L8.16637 10.9623L8.16636 10.9623L8.16429 10.9643C8.06798 11.0607 8.02312 11.0667 7.99999 11.0667C7.94107 11.0667 7.89002 11.0523 7.82025 10.9991L2.08487 5.36351C1.99087 5.26871 1.99115 5.1303 2.0857 5.03575C2.18052 4.94093 2.31947 4.94093 2.41429 5.03575Z"
                fill="#637381"
                stroke="#637381"
                strokeWidth="0.666667"
              />
            </svg>
          </div>
          {isStatusOpen && (
            <div className="absolute z-10 mt-14 w-72 bg-white border border-gray-300 rounded-md shadow-lg">
              {statuses.map((status) => (
                <div
                  key={status.id}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleStatusSelect(status)}
                >
                  {status.name}
                </div>
              ))}
            </div>
          )}
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 w-72  rounded-md p-3 focus:outline-none focus:border-primary-500"
          />
        </div>
      </div>

      {status.id === "listings" && <ReviewListing searchTerm={searchTerm} />}
      {status.id === "merchants" && <ReviewMerchant searchTerm={searchTerm} />}
      {status.id === "accounts" && <ReviewAccount searchTerm={searchTerm} />}
    </div>
  );
};

export default ForReview;
