import * as React from "react";
export const Calendar = (props) => {
  const { fill } = props;

  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_4110_508)">
        <path
          d="M6.40625 11.4375H5.4375C5.25 11.4375 5.125 11.5625 5.125 11.75V12.6875C5.125 12.875 5.25 13 5.4375 13H6.40625C6.59375 13 6.71875 12.875 6.71875 12.6875V11.75C6.71875 11.5938 6.5625 11.4375 6.40625 11.4375Z"
          fill={fill}
        />
        <path
          d="M10.4688 11.4375H9.5C9.3125 11.4375 9.1875 11.5625 9.1875 11.75V12.6875C9.1875 12.875 9.3125 13 9.5 13H10.4688C10.6562 13 10.7812 12.875 10.7812 12.6875V11.75C10.7812 11.5938 10.6562 11.4375 10.4688 11.4375Z"
          fill={fill}
        />
        <path
          d="M14.5625 11.4375H13.5938C13.4063 11.4375 13.2812 11.5625 13.2812 11.75V12.6875C13.2812 12.875 13.4063 13 13.5938 13H14.5625C14.75 13 14.875 12.875 14.875 12.6875V11.75C14.875 11.5938 14.75 11.4375 14.5625 11.4375Z"
          fill={fill}
        />
        <path
          d="M6.40625 15.4688H5.4375C5.25 15.4688 5.125 15.5938 5.125 15.7813V16.7188C5.125 16.9063 5.25 17.0313 5.4375 17.0313H6.40625C6.59375 17.0313 6.71875 16.9063 6.71875 16.7188V15.7813C6.71875 15.625 6.5625 15.4688 6.40625 15.4688Z"
          fill={fill}
        />
        <path
          d="M10.4688 15.4688H9.5C9.3125 15.4688 9.1875 15.5938 9.1875 15.7813V16.7188C9.1875 16.9063 9.3125 17.0313 9.5 17.0313H10.4688C10.6562 17.0313 10.7812 16.9063 10.7812 16.7188V15.7813C10.7812 15.625 10.6562 15.4688 10.4688 15.4688Z"
          fill={fill}
        />
        <path
          d="M14.5625 15.4688H13.5938C13.4063 15.4688 13.2812 15.5938 13.2812 15.7813V16.7188C13.2812 16.9063 13.4063 17.0313 13.5938 17.0313H14.5625C14.75 17.0313 14.875 16.9063 14.875 16.7188V15.7813C14.875 15.625 14.75 15.4688 14.5625 15.4688Z"
          fill={fill}
        />
        <path
          d="M17.5 4.8125H10.7187V4.21875C11.4375 3.9375 11.9375 3.25 11.9375 2.4375C11.9375 1.375 11.0625 0.53125 10 0.53125C8.9375 0.53125 8.0625 1.375 8.0625 2.4375C8.0625 3.25 8.5625 3.9375 9.28125 4.21875V4.8125H2.5C1.4375 4.8125 0.53125 5.6875 0.53125 6.78125V17.5C0.53125 18.5625 1.40625 19.4687 2.5 19.4687H17.5C18.5625 19.4687 19.4687 18.5937 19.4687 17.5V6.75C19.4687 5.6875 18.5625 4.8125 17.5 4.8125ZM10 1.9375C10.2812 1.9375 10.5312 2.15625 10.5312 2.4375C10.5312 2.71875 10.3125 2.9375 10 2.9375C9.6875 2.9375 9.46875 2.71875 9.46875 2.4375C9.46875 2.15625 9.71875 1.9375 10 1.9375ZM2.5 6.21875H17.5C17.8125 6.21875 18.0625 6.46875 18.0625 6.78125V8.71875H1.96875V6.78125C1.96875 6.4375 2.1875 6.21875 2.5 6.21875ZM17.5 18.0625H2.5C2.1875 18.0625 1.9375 17.8125 1.9375 17.5V10.0937H18.0312V17.5C18.0625 17.8125 17.8125 18.0625 17.5 18.0625Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4110_508">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
