import * as React from "react";

export const Cottage = (props) => {
  const { fill } = props;

  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M18.4688 6.5L17.6875 3.84375C17.4688 3.09375 16.75 2.5625 15.9687 2.5625H13.5L12.9375 1.03125C12.8438 0.75 12.5625 0.5625 12.2812 0.5625H7.71875C7.40625 0.5625 7.15625 0.75 7.0625 1.03125L6.5 2.5625H4.03125C3.25 2.5625 2.53125 3.09375 2.3125 3.84375L1.53125 6.5C1.375 7.03125 1.46875 7.625 1.8125 8.0625C2.15625 8.53125 2.6875 8.78125 3.25 8.78125H3.4375V17.6563C3.4375 18.625 4.25 19.4375 5.21875 19.4375H14.8125C15.7812 19.4375 16.5937 18.625 16.5937 17.6563V8.78125H16.7812C17.3438 8.78125 17.875 8.53125 18.2188 8.0625C18.5625 7.59375 18.625 7.03125 18.4688 6.5ZM8.21875 1.96875H11.7812L12 2.5625H8L8.21875 1.96875ZM11.5312 18.0625H8.46875V14.1875C8.46875 13.9688 8.625 13.8125 8.84375 13.8125H11.125C11.3438 13.8125 11.5 13.9688 11.5 14.1875V18.0625H11.5312ZM15.1875 17.6563C15.1875 17.875 15.0312 18.0313 14.8125 18.0313H12.9375V14.1875C12.9375 13.2188 12.125 12.4063 11.1562 12.4063H8.875C7.90625 12.4063 7.09375 13.2188 7.09375 14.1875V18.0313H5.21875C5 18.0313 4.84375 17.875 4.84375 17.6563V8.78125H15.1875V17.6563ZM17.0625 7.21875C17.0312 7.28125 16.9375 7.375 16.75 7.375H3.21875C3.0625 7.375 2.96875 7.28125 2.90625 7.21875C2.875 7.15625 2.8125 7.0625 2.875 6.875L3.65625 4.21875C3.71875 4.0625 3.84375 3.9375 4.03125 3.9375H15.9687C16.125 3.9375 16.2812 4.0625 16.3437 4.21875L17.125 6.875C17.1875 7.0625 17.125 7.15625 17.0625 7.21875Z"
        fill={fill}
      />
    </svg>
  );
};
