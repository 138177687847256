import Loader from "./Loader";

export const PrimaryButton = ({
  title,
  onClick,
  svg,
  isLoading = false,
  reverse = false,
}) => {
  return (
    <button
      className={`${reverse? "border border-primary-500 text-primary-500 hover:opacity-90": "bg-primary-500 text-white hover:opacity-90"} w-full font-semibold py-3 rounded-lg`}
      onClick={
        isLoading
          ? () => {}
          : () => {
              onClick();
            }
      }
    >
      {svg}
      {isLoading ? <Loader /> : <p className="text-sm">{title}</p>}
    </button>
  );
};
