// ServiceTypes.js
import React, { useEffect, useState } from "react";
import ServiceTypesModal from "./Modal";
import { serviceType } from "../../../api/admin";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

const ServiceTypes = () => {
  const navigate = useNavigate();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setData] = useState({ flag: "", code: "" });

  useEffect(() => {
    handleInitial();
  }, []);

  const handleInitial = async () => {
    try {
      const _requestData = {
        flag: "init",
      };
      const response = await serviceType(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        setServiceTypes(response.data.services_type);
      } else {
        navigate(-1);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleEdit = (service_type_code) => {
    setData({ flag: "update", code: service_type_code });
    setShowModal(true);
  };

  const handleUpdateStatus = async (type) => {
    try {
      const _requestData = {
        flag: "update",
        service_type_code: type.service_type_code,
        status: type.status === "0" ? "1" : "0",
        service_name: type.service_name,
      };
      const response = await serviceType(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        handleInitial();
      } else {
        navigate(-1);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleAdd = () => {
    setData({ flag: "add", code: "" });
    setShowModal(true);
  };

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 160);

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight - 160);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="text-sm px-4 pb-4 w-full justify-center items-center">
      <div className="border bg-white p-4 rounded-xl mb-4 w-full flex flex-row justify-between">
        <h2 className="text-xl flex items-center text-center font-semibold">
          Service Types
        </h2>
        <button
          className="flex flex-row gap-x-4 bg-primary-500 hover:bg-primary-600 text-white py-3 px-4 rounded-lg focus:outline-none focus:bg-primary-600"
          onClick={handleAdd}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_4117_1682)">
              <path
                d="M18.75 9.3125H10.7187V1.25C10.7187 0.875 10.4062 0.53125 10 0.53125C9.625 0.53125 9.28125 0.84375 9.28125 1.25V9.3125H1.25C0.875 9.3125 0.53125 9.625 0.53125 10.0312C0.53125 10.4062 0.84375 10.75 1.25 10.75H9.3125V18.75C9.3125 19.125 9.625 19.4687 10.0312 19.4687C10.4062 19.4687 10.75 19.1562 10.75 18.75V10.7187H18.75C19.125 10.7187 19.4687 10.4062 19.4687 10C19.4687 9.625 19.125 9.3125 18.75 9.3125Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_4117_1682">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Add Service Type
        </button>
      </div>

      <div
        className="w-full overflow-y-auto"
        style={{ maxHeight: tableHeight }}
      >
        {isLoading && (
          <div className="flex items-center justify-center h-20">
            <Loader borderColor="border-primary-500" />
          </div>
        )}

        {!isLoading && (
        <div className="rounded-lg border overflow-hidden">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="font-semibold p-3 text-left text-xs text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {serviceTypes.map((type) => (
              <tr key={type.service_type_code} className="hover:bg-gray-50 border-t">
                <td className="p-3 text-left">
                  {type.service_type_code}
                </td>
                <td className="p-3 text-left">
                  {type.service_name}
                </td>
                <td className="p-3 text-left">
                  <span
                    className={`${
                      type.status === "0" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-500"
                    } rounded-full text-left px-2 py-1 text-xs font-semibold`}
                  >
                    {type.status === "0" ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="border-b border-gray-200 p-3">
                  <button
                    className="text-left mr-2 text-blue-500 "
                    onClick={() => handleEdit(type.service_type_code)}
                  >
                    Update
                  </button>
                  <button
                    className={`text-left ${
                      type.status === "0" ? "text-red-500 " : "text-green-500 "
                    } ml-2`}
                    onClick={() => handleUpdateStatus(type)}
                  >
                    {type.status === "0" ? "Deactivate" : "Activate"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        )}
      </div>
      {/* Modal */}
      {showModal && (
        <ServiceTypesModal
          selectedData={selectedData}
          handleClose={() => {
            setData({ flag: "", code: "" });
            setShowModal(false);
          }}
          handleInitial={() => handleInitial()}
        />
      )}
    </div>
  );
};

export default ServiceTypes;
