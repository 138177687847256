import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import CLogged from "./components/admin/CLogged";
import Dashboard from "./components/admin";
import Protected from "./components/common/Auth/Protected";
import Login from "./components/admin/Login";
import { Toaster } from "react-hot-toast";
import ServiceTypes from "./components/admin/ServicesTypes";
import Sidebar from "./components/admin/Sidebar";
import ReviewMerchant from "./components/admin/ForReview/ReviewMerchant";
import ReviewListing from "./components/admin/ForReview/ReviewListing";
import Reports from "./components/admin/Reports";
import ReviewAccount from "./components/admin/ForReview/ReviewAccount";
import Clients from "./components/admin/Clients";
import Merchants from "./components/admin/Merchants";
import Listings from "./components/admin/Listings";
import ManualPayment from "./components/admin/ManualPayment";
import Events from "./components/admin/Events";
import Topbar from "./components/common/Topbar";
import ForReview from "./components/admin/ForReview";

const Layout = ({ children }) => (
  <div
    id="routes"
    className="flex h-screen"
    style={{ backgroundColor: "#F9FAFB" }}
  >
    <Sidebar />
    <div className="w-full">
      <Topbar />
      {children}
    </div>
  </div>
);

const AppRouter = () => {
  return (
    <CLogged>
      <Toaster position="top-right" reverseOrder={false} />

      <div className="text-gray-500  select-none text-base flex flex-col min-h-screen">
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <div id="routes" className="flex-grow">
                  <Login />
                </div>
              }
            />

            <Route
              path="/"
              element={
                <Layout>
                  <Protected>
                    <Dashboard />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/servicetypes"
              element={
                <Layout>
                  <Protected>
                    <ServiceTypes />
                  </Protected>
                </Layout>
              }
            />
            <Route
              path="/reviewmerchants"
              element={
                <Layout>
                  <Protected>
                    <ReviewMerchant />
                  </Protected>
                </Layout>
              }
            />
            <Route
              path="/clients"
              element={
                <Layout>
                  <Protected>
                    <Clients />
                  </Protected>
                </Layout>
              }
            />
            <Route
              path="/listings"
              element={
                <Layout>
                  <Protected>
                    <Listings />
                  </Protected>
                </Layout>
              }
            />
            <Route
              path="/merchants"
              element={
                <Layout>
                  <Protected>
                    <Merchants />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/for-review"
              element={
                <Layout>
                  <Protected>
                    <ForReview />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/reviewlistings"
              element={
                <Layout>
                  <Protected>
                    <ReviewListing />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/reviewaccounts"
              element={
                <Layout>
                  <Protected>
                    <ReviewAccount />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/reports"
              element={
                <Layout>
                  <Protected>
                    <Reports />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/payments"
              element={
                <Layout>
                  <Protected>
                    <ManualPayment />
                  </Protected>
                </Layout>
              }
            />

            <Route
              path="/events"
              element={
                <Layout>
                  <Protected>
                    <Events />
                  </Protected>
                </Layout>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </CLogged>
  );
};

export default AppRouter;
