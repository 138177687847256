import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { ModalHeader } from "../../../common/ModalHeader";
import { reviewAccount } from "../../../../api/admin";
import Loader from "../../../common/Loader";

const ReviewAccountModal = ({ selectedData, handleClose, handleInitial }) => {
  const [account, setAccount] = useState({
    account_info: {
      account_code: "",
      firstname: "",
      middlename: "",
      lastname: "",
      gender: "",
      birthdate: null,
      mobile_no: "",
      email_address: "",
      email_status: null,
      profile_img: "",
      status: "",
      date_created: "",
      date_modified: "",
      full_name: "",
    },
    account_application: [],
  });

  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        account_code: selectedData?.code,
      };
      const response = await reviewAccount(_requestData);
      if (response.error === 0) {
        setAccount(response.data);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  useEffect(() => {
    handleView();
  }, []);

  const closeModal = () => {
    setAccount({
      account_info: {
        account_code: "",
        firstname: "",
        middlename: "",
        lastname: "",
        gender: "",
        birthdate: null,
        mobile_no: "",
        email_address: "",
        email_status: null,
        profile_img: "",
        status: "",
        date_created: "",
        date_modified: "",
        full_name: "",
      },
      account_application: [],
    });
    setIsLoading(false);
    handleClose();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: selectedData.flag,
        remarks: remarks,
        account_code: selectedData.code,
      };

      const response = await reviewAccount(_requestData);
      if (response.error === 0) {
        toast(response.msg);
        setIsLoading(false);
        handleInitial();
        closeModal();
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast("Failed. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-3xl">
        <ModalHeader title={"Review Account"} onClick={closeModal} type={1} />
        <div>
          <div className="flex flex-wrap gap-4 justify-center">
            {!account.account_info.profile_img ? (
              <svg
                width="112"
                height="112"
                viewBox="0 0 112 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="112" height="112" rx="56" fill="#F3F4F6" />
                <path
                  d="M64.6 56.9998C63.1 55.1998 61.2 53.9498 59.1 53.2998C61.2 52.1998 62.6 49.9998 62.6 47.4498C62.6 43.7998 59.65 40.7998 55.95 40.7998C52.25 40.7998 49.35 43.8498 49.35 47.4998C49.35 49.9998 50.75 52.1998 52.85 53.3498C50.75 53.9998 48.9 55.2498 47.35 57.0498C45.15 59.6498 43.9 63.2498 43.85 67.1998C43.85 67.6498 44.1 68.0498 44.45 68.1998C45.8 68.8998 50.7 71.0998 55.95 71.0998C61.65 71.0998 66.2 68.8498 67.5 68.1498C67.85 67.9498 68.1 67.5498 68.1 67.1498C68.05 63.2498 66.8 59.6498 64.6 56.9998ZM56 43.1498C58.4 43.1498 60.4 45.0998 60.4 47.5498C60.4 49.9998 58.45 51.9498 56 51.9498C53.55 51.9498 51.6 49.9998 51.6 47.5498C51.6 45.0998 53.6 43.1498 56 43.1498ZM56 68.8998C51.85 68.8998 47.8 67.2998 46.15 66.4998C46.35 63.3498 47.35 60.5498 49.1 58.4498C50.9 56.2998 53.35 55.0998 56 55.0998C58.65 55.0998 61.1 56.2998 62.9 58.4498C64.65 60.4998 65.7 63.3498 65.85 66.4998C64.3 67.2998 60.5 68.8998 56 68.8998Z"
                  fill="#CED4DA"
                />
              </svg>
            ) : (
              <div className="w-28">
                <a
                  href={account.account_info.profile_img}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={account.account_info.profile_img}
                    alt="Profile"
                    className="h-28  w-full rounded-full"
                  />
                </a>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 items-center align-center">
            <div className="mt-2 items-center ">
              <p className="text-lg font-semibold text-center select-all">
                {account.account_info.full_name}
              </p>
              <p className="select-all text-gray-400 text-center">
                {account.account_info.account_code}
              </p>
            </div>
          </div>

          {/* Additional Details */}
          <div className=" border-t w-full my-4"></div>
          <div className="flex flex-col justify-center">
            <div className="flex">
              <p className="w-40">Mobile Number</p>
              <div className="flex">
                <p className="mr-1">:</p>
                <p className=" select-all">{account.account_info.mobile_no}</p>
              </div>
            </div>

            <div className="flex">
              <p className="w-40">Email Address</p>
              <div className="flex">
                <p className="mr-1">:</p>
                <p className="select-all">
                  {account.account_info.email_address}
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="w-40">Date of Birth</p>
              <div className="flex">
                <p className="mr-1">:</p>
                <p className=" select-all">{account.account_info.birthdate}</p>
              </div>
            </div>
            <div className="flex">
              <p className="w-40">Date Created</p>
              <div className="flex">
                <p className="mr-1">:</p>
                <p className=" select-all">
                  {account.account_info.date_created}
                </p>
              </div>
            </div>

            {!isLoading && account.account_application.length !== 0 && (
              <div className="flex flex-col my-4">
                <p className="text-gray-400 text-sm mb-2">
                  Supporting Documents
                </p>
                <div className="flex gap-4 overflow-x-auto pb-2">
                  {account.account_application?.map((doc, index) => (
                    <div key={index} className="w-40 flex-shrink-0">
                      <a href={doc} target="_blank" rel="noopener noreferrer">
                        <img
                          src={doc}
                          alt={`Document ${index}`}
                          className="w-full rounded-lg border"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="py-4">
          <label className="text-gray-400 text-xs">Remarks</label>
          <input
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            className="w-full p-3 border rounded-lg focus:outline-none focus:border-primary-500"
          />
        </div>

        <div className="text-center flex gap-x-4">
          <button
            onClick={() => closeModal()}
            className="w-full border p-3 rounded-lg hover:opacity-90"
          >
            Close
          </button>

          <button
            onClick={handleSave}
            className="w-full bg-primary-500 hover:bg-primary-600 capitalize text-white py-2 px-4 rounded-lg focus:outline-none focus:bg-primary-600"
          >
            {isLoading ? <Loader /> : selectedData.flag}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewAccountModal;
