import React, { useState, useEffect } from "react";
import { ModalHeader } from "../../common/ModalHeader";
import { toast } from "react-hot-toast";
import { serviceType } from "../../../api/admin";
import Loader from "../../common/Loader";

const ServiceTypesModal = ({ selectedData, handleClose, handleInitial }) => {
  const [serviceName, setServiceName] = useState();
  const [needPax, setNeedPax] = useState(false);
  const [serviceImg, setServiceImg] = useState();
  const [serviceFile, setServiceFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleView = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: "view",
        service_type_code: selectedData?.code,
      };
      const response = await serviceType(_requestData);
      if (response.error === 0) {
        setServiceName(response.data.service_type.service_name);
        setNeedPax(response.data.service_type.need_pax === "1");
        setServiceImg(response.data.service_type.service_img);
        setIsLoading(false);
      } else {
        closeModal();
      }
    } catch (error) {
      closeModal();
    }
  };

  useEffect(() => {
    if (selectedData?.flag === "update") {
      handleView();
    }
  }, []);

  const closeModal = () => {
    setServiceName(selectedData?.name || "");
    setNeedPax(false);
    setServiceImg();
    setServiceFile();
    setIsLoading(false);
    handleClose();
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        flag: selectedData.flag,
        service_name: serviceName,
        need_pax: needPax ? 1 : 0,
        service_type_code: selectedData?.code,
        ...(serviceFile && { service_img: serviceFile }),
      };

      const response = await serviceType(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        handleInitial()
        closeModal();
        toast.success(response.msg)
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast("Failed. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setServiceImg(URL.createObjectURL(file));
    setServiceFile(file);
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-xl shadow-md w-full max-w-md">
        <ModalHeader
          title={"Add Service Types"}
          onClick={() => {
            closeModal();
          }}
          type={0}
        />

        <div className="bg-gray-50 p-4 border border-dashed rounded-xl">
          {serviceImg && (
            <div className="mt-4 text-center-webkit">
              <img
                src={serviceImg}
                alt="Service"
                className="w-full h-60 rounded-lg"
              />
            </div>
          )}

          <div className="mt-4 text-center-webkit w-full">
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              id="serviceFileInput"
            />
            <label
              htmlFor="serviceFileInput"
              className="flex w-max gap-x-4  bg-primary-500 hover:bg-primary-600 text-white p-4 rounded-xl focus:outline-none focus:bg-primary-600 cursor-pointer"
            >
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                  d="M19.25 13.75C18.875 13.75 18.5313 14.0625 18.5313 14.4687V17.25C18.5313 17.5312 18.3125 17.75 18.0312 17.75H2.96875C2.6875 17.75 2.46875 17.5312 2.46875 17.25V14.4687C2.46875 14.0625 2.125 13.75 1.75 13.75C1.375 13.75 1.03125 14.0625 1.03125 14.4687V17.25C1.03125 18.3125 1.875 19.1562 2.9375 19.1562H18.0312C19.0938 19.1562 19.9375 18.3125 19.9375 17.25V14.4687C19.9688 14.0625 19.625 13.75 19.25 13.75Z"
                  fill="white"
                />
                <path
                  d="M6.46875 6.46875L9.8125 3.21875V14.0313C9.8125 14.4063 10.125 14.75 10.5312 14.75C10.9062 14.75 11.25 14.4375 11.25 14.0313V3.21875L14.5937 6.46875C14.7187 6.59375 14.9062 6.65625 15.0938 6.65625C15.2812 6.65625 15.4688 6.59375 15.5938 6.4375C15.875 6.15625 15.8438 5.71875 15.5938 5.4375L11 1.0625C10.7187 0.8125 10.2812 0.8125 10.0312 1.0625L5.46875 5.46875C5.1875 5.75 5.1875 6.1875 5.46875 6.46875C5.75 6.71875 6.1875 6.75 6.46875 6.46875Z"
                  fill="white"
                />
              </svg>
              Upload Image
            </label>
          </div>
        </div>


        <div className="my-4">
          <input
            type="text"
            placeholder="Service Type"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            checked={needPax}
            onChange={(e) => setNeedPax(e.target.checked)}
            id="needPaxCheckbox"
            className="mr-2 appearance-none border rounded-md w-6 h-6 checked:bg-primary-500 checked:border-transparent focus:outline-none"
          />
          <label htmlFor="needPaxCheckbox" className="text-gray-800">
            Need Pax
          </label>
        </div>

        <div className="text-center flex gap-x-4 w-full mt-4">
        <button
            onClick={() => closeModal()}
            className="w-full border p-3 rounded-lg hover:opacity-90"
          >
            Cancel
          </button>
          
          <button
            onClick={() => handleSave()}
            className="w-full bg-primary-500 hover:bg-primary-600 text-white p-3 rounded-lg focus:outline-none focus:bg-primary-600"
          >
            {isLoading ? <Loader /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypesModal;
